import React, { useState, useEffect } from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BannerFour from '../component/banner/BannerFour';
import BlogOne from '../component/blog/BlogOne';
import BrandOne from '../component/brand/BrandOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ProjectFour from '../component/project/ProjectFour';
import TestimonialOne from '../component/testimonial/TestimonialOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import { Helmet } from 'react-helmet';
import WhatsAppButton from './WhatsAppButton';
import PhoneButton from './PhoneButton';

const HomeStartup = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate loading time
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    if (isLoading) {
        return (
            <div className="preloader" style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundColor: 'var(--color-white)'
            }}>
                <div className="loading-spinner">
                    <img 
                        src={process.env.PUBLIC_URL + "/images/logo-2.webp"} 
                        alt="Loading..."
                        style={{
                            animation: 'pulse 1s infinite',
                            width: '100px'
                        }}
                    />
                </div>
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
                <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
                <title>كاتبي للحلول الرقمية</title>
                <meta name="description" content="اكتشف حلولنا الرقمية المتكاملة والمبتكرة لتحسين أدائك التجاري وزيادة الكفاءة في عالم التكنولوجيا." />
                <meta name="keywords" content="كاتبي, katbi, حلول رقمية, تكنولوجيا, تطوير الأعمال, التجارة الإلكترونية, تحسين الأعمال, برمجة, تطبيقات موبايل" />
            </Helmet>
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BannerFour />
                <ProjectFour />
                <TestimonialOne />
                <BrandOne />
                <BlogOne />
                <CtaLayoutOne />
                <FooterOne />
                <WhatsAppButton />
                <PhoneButton />

            </main>
        </>
    );
};

export default HomeStartup;
