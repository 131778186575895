import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ServiceProp from '../component/service/ServiceProp';
import ServiceData from "../data/service/ServiceMain.json";
import { slugifyArabic } from '../utils';
import WhatsAppButton from './WhatsAppButton';
import { Helmet } from 'react-helmet';
import PhoneButton from './PhoneButton';

const allData = ServiceData;

const ServiceOne = () => {

    const designData = allData.filter(data => slugifyArabic(data.cate ? data.cate : "") === "design");
    const developmentData = allData.filter(data => slugifyArabic(data.cate ? data.cate : "") === "development");
    const marketingData = allData.filter(data => slugifyArabic(data.cate ? data.cate : "") === "online-marketing");
    const businessData = allData.filter(data => slugifyArabic(data.cate ? data.cate : "") === "business");
    const technologyData = allData.filter(data => slugifyArabic(data.cate ? data.cate : "") === "technology");
    const strategyData = allData.filter(data => slugifyArabic(data.cate ? data.cate : "") === "content-strategy");

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <meta name="description" content="أفضل الحلول الرقمية لأعمالك التجارية" />
                <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
                <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
                <title>كاتبي للحلول الرقمية</title>
            </Helmet>

            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne 
                    title="أفضل الحلول الرقمية لأعمالك التجارية"
                    paragraph="امنح عملك شعارًا فريدًا ليتميز عن الآخرين. سنقوم بإنشاءه لشركتك."
                    styleClass=""
                    mainThumb="/images/banner/banner-thumb-4.png"
                />
                <div className="service-scroll-navigation-area">
                    {/* Service Nav */}
                    <nav id="onepagenav" className="service-scroll-nav navbar onepagefixed">
                        <div className="container">
                            <ul className="nav nav-pills">
                                <li className="nav-item">
                                    <a className="nav-link" href="#section1">التصميم</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#section2">التطوير</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#section3">التسويق الرقمي</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#section6">إدارة المحتوى</a>
                                </li>
                            </ul>
                        </div>
                    </nav>

                    <div className="section section-padding" id="section1">
                        <div className="container">
                            <SectionTitle 
                                subtitle="خدمات"
                                title="التصميم"
                                description=""
                                textAlignment="heading-left"
                                textColor=""
                            />
                            <div className="row">
                                <ServiceProp colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" serviceData={designData} />
                            </div>
                        </div>
                    </div>

                    <div className="section section-padding bg-color-light" id="section2">
                        <div className="container">
                            <SectionTitle 
                                subtitle="خدمات"
                                title="التطوير"
                                description=""
                                textAlignment="heading-left"
                                textColor=""
                            />
                            <div className="row">
                                <ServiceProp colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" serviceData={developmentData} />
                            </div>
                        </div>
                    </div>

                    <div className="section section-padding" id="section3">
                        <div className="container">
                            <SectionTitle 
                                subtitle="خدمات"
                                title="التسويق الرقمي"
                                description=""
                                textAlignment="heading-left"
                                textColor=""
                            />
                            <div className="row">
                                <ServiceProp colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" serviceData={marketingData} />
                            </div>
                        </div>
                    </div>

                    <div className="section section-padding bg-color-light" id="section6">
                        <div className="container">
                            <SectionTitle 
                                subtitle="خدمات"
                                title="إدارة المحتوى"
                                description=""
                                textAlignment="heading-left"
                                textColor=""
                            />
                            <div className="row">
                                <ServiceProp colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" serviceData={strategyData} />
                            </div>
                        </div>
                    </div>
                </div>
                <CtaLayoutOne />
                <FooterOne parentClass="" />
                <WhatsAppButton />
                <PhoneButton />
            </main>
        </>
    );
}

export default ServiceOne;
