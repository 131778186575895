import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import CounterUpTwo from '../component/counterup/CounterUpTwo';
import TeamOne from '../component/team/TeamOne';
import VideoTwo from '../component/video/VideoTwo';
import { Helmet } from 'react-helmet';
import WhatsAppButton from './WhatsAppButton';
import PhoneButton from './PhoneButton';

const Team = () => {
    return (
        <>
            <Helmet>
                <title>بلال كاتبي | كاتبي للحلول الرقمية</title>
                <meta name="description" content="تعرف على فريقنا الموهوب وذوي الخبرة. نمِّ عملك عبر الإنترنت باستخدام خبراتنا في تصميم التطبيقات المحمولة التي تناسب جميع الأنواع." />
                <link rel="canonical" href="https://www.katbi.net/about/بلال-كاتبي" />
            </Helmet>
            <SEO title="الفريق" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne 
                    title="تعرف على الموهبة والخبرة"
                    paragraph="نمِّ عملك عبر الإنترنت من خلال تطبيقات مصممة بشكل رائع تناسب جميع الأنواع."
                    styleClass="thumbnail-2"
                    mainThumb="/images/team/team-1.png"
                />
                <CounterUpTwo />
                <TeamOne />
                <VideoTwo />
                <CtaLayoutOne />
                <FooterOne parentClass="" />
                <WhatsAppButton />
                <PhoneButton />

            </main>
        </>
    );
}

export default Team;
