import React from 'react';

const PhoneButton = () => {
  const phoneNumber = '+971505733543';
  
  return (
    <a href={`tel:${phoneNumber}`} className="phone-button">
      <img
        src={process.env.PUBLIC_URL + "/images/phone-icon.png"}
        alt="Phone"
        className="phone-icon"
      />
    </a>
  );
};

export default PhoneButton;